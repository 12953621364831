.table_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;

  .table_content_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
  }

  .table_select_container {
    display: flex;
    align-items: center;

    .select_label_table {
      margin-right: 8px;
    }
  }

  .table_select {
    width: 300px;
    margin-right: 24px;
  }

  .table_select_label {
    margin-right: 8px;
  }

  .table_team {
    width: 100%;
    margin: 0 auto;

    .table_align_left {
      text-align: left;
      vertical-align: middle;
    }
    .table_align_center {
      text-align: center;
      vertical-align: middle;
    }

    .table_align_right {
      text-align: right;
      vertical-align: middle;
    }

    .row_one {
      background-color: #fff172;
    }

    .row_one:hover {
      background-color: rgba(255, 22, 22, 0.2);
    }

    .row_two {
      background-color: #b9b8bd;
    }

    .row_three {
      background-color: #fe9d4c;
    }
  }
}

.table_team tbody tr td {
  pointer-events: none;
}

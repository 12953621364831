.round_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;

  .round_content_top {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .round_select_container {
      display: flex;
      align-items: center;
      .round_select {
        margin-right: 24px;
        width: 300px;
      }
      .select_label_table {
        margin-right: 8px;
      }
    }
  }

  .round_content_bottom {
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 286px);

    .round_collapse_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 10px;

      .name_of_round {
        font-weight: 600;
      }
    }
  }
}

.analyticsPlayer_content_top {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .select_container {
    display: flex;
    align-items: center;

    .tour_select,
    .team_select {
      margin-left: 8px;
      min-width: 300px;
      margin-right: 24px;
    }

    .select_label_team {
      margin-left: 24px;
    }
  }
}

.pagination {
  margin-top: 15px;
  float: right;
}

.table_align_center {
  text-align: center !important;
  vertical-align: middle;
}

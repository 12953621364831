.publicLayout_content {
  background-color: #f5f5f5;
  height: 100vh;
}

.round_container {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px;
  height: 100%;
  background-color: white;

  .match_row:last-child td {
    border-bottom: 0 !important;
  }
  .match_row td {
    padding: 0 !important;
  }

  .match_content_info {
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
    padding: 16px 16px;

    .goal,
    .team_1,
    .team_2 {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .team_1 {
      justify-content: flex-end;
      color: #676767;
    }
    .team_2 {
      color: #676767;
    }
    .goal {
      justify-content: center;
    }
    .team_1 > *:not(:last-child),
    .team_2 > *:not(:last-child) {
      margin-right: 20px;
    }

    .name_team {
      font-weight: 700;
    }
    .goal_content {
      width: fit-content;
      padding: 0 12px;
      background-color: #f3f3f3;
      border-radius: 20px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1677ff;

      .goal_dash {
        margin: 0 2px;
        margin-top: -3px;
      }
    }
  }

  .round_content_top {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .round_select_container {
      display: flex;
      align-items: center;
      .round_select {
        margin-right: 24px;
        width: 300px;
      }
      .select_label_table {
        margin-right: 8px;
      }
    }
  }

  .round_content_bottom {
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 143px);

    .round_collapse_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 10px;

      .name_of_round {
        font-weight: 600;
      }
    }
  }
}

.main_layout {
  height: 100vh;
  background-color: white;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;

  .login_form {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .layout_login_left {
    flex: 1;
    margin: 15px;
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .btn_login {
    width: 100%;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

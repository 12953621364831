::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(23, 120, 255, 0.4);
  border-radius: 50px;
}

.adminLayout_container {
  min-height: 100vh;

  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: auto 1fr;
  overflow: auto;
}
.adminLayout_info {
  min-width: 824px;
  width: 100% !important;
}
.adminLayout_content {
  margin: 0 16px;
}

.adminLayout_content_detail {
  padding: 24px;
  // min-height: calc(100vh - 160px);
  height: calc(100vh - 160px);
  margin: 16px 0;
}

.adminFooter {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
}

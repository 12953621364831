.footer_modal_import_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.upload > div:first-child {
  display: none;
}

.table_select {
  margin: 12px 0;
}

.select_label_table {
  // margin-right: 8px;
}

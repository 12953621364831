.match_row:last-child td {
  border-bottom: 0 !important;
}
.match_row td {
  padding: 0 !important;
}

.match_content_info {
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
  padding: 16px 16px;
  cursor: pointer;

  .goal,
  .team_1,
  .team_2 {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .team_1 {
    justify-content: flex-end;
    color: #676767;
  }
  .team_2 {
    color: #676767;
  }
  .goal {
    justify-content: center;
  }
  .team_1 > *:not(:last-child),
  .team_2 > *:not(:last-child) {
    margin-right: 20px;
  }

  .name_team {
    font-weight: 700;
  }
  .goal_content {
    width: fit-content;
    padding: 0 12px;
    background-color: #f3f3f3;
    border-radius: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    .goal_dash {
      margin: 0 2px;
      margin-top: -3px;
    }
  }
}

.detailMatch_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .detailMatch_top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .detailMatch_date {
      color: #676767;
      font-size: 20px;
    }

    .detailMatch_result {
      display: grid;
      grid-template-columns: 1fr 130px 1fr;
      grid-gap: 10px;
      justify-items: center;
      align-items: center;
      padding: 40px 0;

      .team_1 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        font-weight: 600;
        width: 100%;
      }
      .team_2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 600;
      }
      .team_name {
        margin-bottom: 12px;
        font-size: 16px;
      }
    }

    .goal {
      color: black;
      width: fit-content;
      padding: 0 22px;
      background-color: #f3f3f3;
      border-radius: 20px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      line-height: 32px;
      .goal_dash {
        margin: 0 6px;
        margin-top: -2px;
      }
    }
  }

  .detailMatch_bottom {
    width: 100%;

    .detailMatch_bottom_content {
      max-height: calc(100vh - 466px);
      overflow-x: auto;
    }

    .color_green {
      color: #00761a;
      font-weight: 700;
    }
    .bench {
      background-color: #fafafa;
      font-weight: 700;
      td:not(.bench_td) {
        display: none;
      }
      .bench_td {
        text-align: center;
      }
    }

    .reward {
      background-color: #fafafa;

      .reward_td {
        font-weight: 700;
      }
      td:not(.reward_td):not(:last-child) {
        display: none;
      }
    }

    .no_play {
      opacity: 0.3;
      .color_green {
        color: rgba(0, 0, 0, 0.88);
      }
    }

    .detailMatch_table {
      thead th {
        font-weight: 700 !important;
      }
    }
  }
}

.analytic_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .analytic_content_top {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .analytic_select_container {
      display: flex;
      align-items: center;

      .analytic_select {
        margin-right: 24px;
        margin-left: 8px;
        width: 300px;
      }
    }
  }

  .analytic_content_bottom {
    width: 100%;
    overflow-y: auto;
    min-height: fit-content;
    // max-height: calc(100vh - 286px);
    height: 100%;
    display: flex;
    margin-top: 0;
    padding: 0 64px;

    .analytic_content_desc-vertical {
      text-align: center;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      transform: rotate(180deg);
      font-weight: bold;
      padding-top: 30px;
      margin-right: 12px;
    }
    .analytic_content_chart {
      width: 100%;
      height: calc(100% - 20px);
      margin: 0;
    }
    .analytic_content_desc-horizontal {
      text-align: center;
      margin: 12px;
      font-weight: bold;
    }
    .analytic_content_empty {
      margin: auto;
    }
  }
}

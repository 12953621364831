@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.loading_wrapper_global {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  background-color: rgb(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

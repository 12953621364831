.adminLayout_logo {
  height: 32px;
  margin: 16px;
  background: white;
  border-radius: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo_unCollapsed {
    display: flex;
    flex-direction: row;
  }
}

.sidebar_container {
  height: 100vh;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  max-width: unset !important;
  // width: unset !important;
}

.sidebar_collapse {
  position: absolute;
  width: 100%;
  height: 48px;
  z-index: 1;
  bottom: 0;
  color: white;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}

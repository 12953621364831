.main_layout {
  height: 100vh;
  position: relative;

  .main_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
